import Vue from 'vue'
import VueRouter from 'vue-router'
import Home1 from '../views/Home2.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home2',
    component: Home1
  },
  {
    path: '/TermsAndConditionMerchant',
    name: 'TermsAndConditionMerchant', // Unique name
    component: () => import('../views/Home1.vue')
  },
{
  
  path: '/TermsAndConditionUser',
    name: 'Home3',
    component: () => import('../views/Home3.vue')
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router